import Index from "./router/router";
import './App.less';

function App() {

  require('dotenv').config({ path: '.env' });

  return (
    <div className="App">
      <Index />
    </div>
  );
}

export default App;

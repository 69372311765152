
import './topBackground.scss';

/**
 * 位于顶部的背景组件
 */
export default function TopBackground(props){
    return (
        <div className="rps-top-background" >
            <ul className="background-bubbles" >
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
            </ul>
        </div>
    );
};
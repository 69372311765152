import React, { Suspense, lazy, useState } from 'react';
import { Router, Route, Switch, Redirect, } from 'react-router';
import { createHashHistory } from 'history';
import { Layout, BackTop } from 'antd';
import Loading from "../comps/Loading";
import HeaderNav from './HeaderNav';
import utils from "../module/utils";
import './footer.scss';
import TopBackground from '../pages/components/TopBackground/TopBackground';

const { Header, Content,Footer } = Layout;

const Home = lazy(() => import("../pages/Home/Home"));
const Browse = lazy(() => import("../pages/Browse/Browse"));
const Search = lazy(() => import("../pages/Search/Search"));
const Blast = lazy(() => import("../pages/Blast/Blast"));
const Help = lazy(() => import("../pages/Help/Help"));
const Download = lazy(() => import("../pages/Download/Download"));
const Citation = lazy(() => import("../pages/Citation/Citation"));
const Contact = lazy(() => import("../pages/Contact/Contact"));
const RPSDetail = lazy(() => import("../pages/RPSDetail/RPSDetail"));
const LLPSDetail = lazy(() => import("../pages/LLPSDetail/LLPSDetail"));
const RNADetail = lazy(() => import("../pages/RNADetail/RNADetail"));
const CondensateDetail = lazy(() => import("../pages/CondensateDetail/CondensateDetail"));
const ViewRNA = lazy(() => import("../pages/ViewRNA/ViewRNA"));
const ViewRNASS = lazy(() => import("../pages/ViewRNASecondaryStructure/ViewRNASS"));
const ViewRNATS = lazy(() => import("../pages/ViewRNAThirdStructure/ViewRNATS"));

export default function RPSRouter(){

    const history = createHashHistory();

    const [isHome, updateIsHome] = useState(true);

    React.useEffect(() => {
        // utils.redirectToHttp(); // 2023.10.14已支持https，去掉重定向
        updateIsHome(utils.isHome()); // 加载页面时先执行一次判断是否在home页
        const unlisten = history.listen((location, action) => { // 监听路由
            updateIsHome(utils.isHome()); //路由改变时执行判断是否在首页
            window.scrollTo(0,0); // 路由改变时先滚动至最上方
        });
        return (() => {
            unlisten();
        });
    },[]);

    return (
        <React.Fragment>
            
            <Layout style={{backgroundColor: "#fff"}} >
                {/* <Header>
                    <HeaderNav />
                </Header> */}
                {!isHome && <TopBackground />}
                <Suspense fallback={<Loading />} >
                    <Router history={history} >
                        <HeaderNav />
                        <Content style={isHome ? {} : {/* width: "88%", */ margin: "auto", /* maxWidth: "1400px", */ minHeight: "90vh", paddingBottom: "50px"}} >
                            <BackTop />
                            <Switch>
                                <Route path="/Home" component={Home} />
                                <Route path="/Browse" component={Browse} />
                                <Route path="/Search/:type?/:keyWords?" component={Search} />
                                <Route path="/Blast" component={Blast} />
                                <Route path="/Help" component={Help} />
                                <Route path="/Download" component={Download} />
                                <Route path="/Citation" component={Citation} />
                                <Route path="/Contact" component={Contact} />
                                <Route path="/rpsDetail/:RPSId/:scrollToId?" component={RPSDetail} />
                                <Route path="/llpsDetail/:LLPSId" component={LLPSDetail} />
                                <Route path="/rnaDetail/:RNAId" component={RNADetail} />
                                <Route path="/condensateDetail/:condensateName" component={CondensateDetail} />
                                <Route path="/viewRNA/:RPSId/" component={ViewRNA} />
                                <Route path="/viewRNASS/:RPSId" component={ViewRNASS} />
                                <Route path="/viewRNATS/:RPSId" component={ViewRNATS} />
                                <Redirect to="/Home" />
                            </Switch>
                        </Content>
                        <Footer style={{display: isHome ? "none" : "block"}} className="rps-footer" >RPS © 2024 The Ren Lab. All Rights Reserved</Footer>
                    </Router>
                </Suspense>
            </Layout>

        </React.Fragment>
    );
}